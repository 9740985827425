import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import lightGallery from "lightgallery";

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
    new hcSticky(".stickybar", {
        stickTo: "body",
        stickyClass: "stickybar--sticked",
    });
});
//#endregion
//#region offcavas mobile
$("[data-trigger]").on("click", function () {
    var trigger_id = $(this).attr("data-trigger");
    $(trigger_id).toggleClass("show");
    $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
    $(".navbar-collapse").removeClass("show");
    $("body").removeClass("offcanvas-active");
});
//#endregion
//#region close topbar mobile
$(".close-topbar").on("click", function () {
    $(".topbar").fadeOut("fast");
});
//#endregion
//#region sliders
$(function () {
    //#region main slider homepage
    $(".sliderhome").slick({
        infinite: true,
        slidesToShow: 1,
        dots: false,
        arrows: true,
        speed: 600,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    adaptiveHeight: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                },
            },
        ],
    });
    //#endregion
    //#region product slider
    $(".slider-product").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        dots: false,
        arrows: false,
        centerPadding: "30px",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    });
    //#endregion
    //#region single product slider
    $(".slider-single-product").slick({
        infinite: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        speed: 600,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    adaptiveHeight: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                },
            },
        ],
    });
    //#endregion
    //#region product crosselling
    $(".slider-product-crosselling").slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        dots: false,
        arrows: false,
        centerPadding: "30px",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    });
    //#endregion
});
//#endregion
//#region input number
$(document).on("click", ".number-spinner button", function () {
    var btn = $(this),
        oldValue = btn.closest(".number-spinner").find("input").val().trim(),
        newVal = 0;

    if (btn.attr("data-dir") == "up") {
        newVal = parseInt(oldValue) + 1;
    } else {
        if (oldValue > 1) {
            newVal = parseInt(oldValue) - 1;
        } else {
            newVal = 1;
        }
    }
    btn.closest(".number-spinner").find("input").val(newVal);
});
//#endregion
//#region show password
$(".show-pw-group a").on("click", function (event) {
    event.preventDefault();
    if ($(".show-pw-group input").attr("type") == "text") {
        $(".show-pw-group input").attr("type", "password");
        $(".show-pw-group i").addClass("fa-eye");
        $(".show-pw-group i").removeClass("fa-eye-slash");
    } else if ($(".show-pw-group input").attr("type") == "password") {
        $(".show-pw-group input").attr("type", "text");
        $(".show-pw-group i").removeClass("fa-eye");
        $(".show-pw-group i").addClass("fa-eye-slash");
    }
});
//#endregion
//#region toggle fatturazione
$(".check-fatturazione").on("click", function () {
    if ($(this).prop("checked")) {
        $(this)
            .parents(".form__custom")
            .find(".fatturazione-diversa")
            .fadeIn("slow");
    } else $(this).parents(".form__custom").find(".fatturazione-diversa").fadeOut("slow");
});
//#endregion
//#region light gallery
$(".gallery__lightgallery__img").each(function (index) {
    var src = $(this).attr("src");
    $(this).closest("a").attr("href", src);
});
if ($(".gallery__lightgallery").length) {
    let lg = document.getElementById("js-lightgallery");
    lg.addEventListener("lgAfterOpen", () => {
        let scrollableLg = document.querySelector(".lg-container");
        disableBodyScroll(scrollableLg);
    });
    lg.addEventListener("lgAfterClose", () => {
        let scrollableLg = document.querySelector(".lg-container");
        enableBodyScroll(scrollableLg);
    });
    lightGallery(lg, {
        selector: ".gallery__lightgallery",
        mobileSettings: {
            controls: true,
            showCloseIcon: true,
            download: true,
        },
    });
}
//#endregion
